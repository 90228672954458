import Dropzone from 'dropzone';
import { Controller } from '@hotwired/stimulus';
import { DirectUpload } from '@rails/activestorage';
import Rails from '@rails/ujs';
import { get } from '@rails/request.js'
import $ from "jquery"

import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter,
} from './../helpers/index.js';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    this.dropZone = createDropZone(this);
    this.autoFiles();
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  autoFiles() {
    let files = this.inputTarget.getAttribute('data-files')
    if (files != undefined && files != null) {
      files = JSON.parse(files)

      for(let file of files) {
        file.status = 'success';
        this.dropZone.emit('addedfile', file);
        this.dropZone.emit('thumbnail', file, file.dataURL);

        let directUploadController = new DirectUploadController(this, file)
        directUploadController.file.controller = directUploadController;
        directUploadController.hiddenInput = directUploadController.createHiddenInput();
        directUploadController.hiddenInput.value = file.signed_id
        directUploadController.emitDropzoneSuccess()
      }
    }
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = 'none';
  }

  bindEvents() {
    this.dropZone.on('addedfile', (file) => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start(() => {
          let element = this.dropZone.element
          if (element.getAttribute('data-dropzone-save') == 'true') {
            Rails.fire(element.closest('form'), 'submit');
          }
        });
      }, 500);
    });

    this.dropZone.on('removedfile', (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on('canceled', (file) => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on('error', (file, error) => {
      let message
      let msgEl = $(file.previewElement).find('.dz-error-message');
      if (error.startsWith('File is too big')) {
        msgEl.text('A imagem que tentou importar é superior a 7MB. Por favor, dê upload de outra imagem.');
        message = 'errors.big_file'
      } else {
        message = 'generic'
      }
      msgEl.show();
      msgEl.css("opacity", 1);

      get(`/user/notify?type=alert&message=${message}`, {
        responseKind: 'json', contentType: 'application/json'
      })

      setTimeout(() => {
        this.dropZone.removeFile(file)
      }, 3000)
    });
  }

  get headers() {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') };
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url');
  }

  get maxFiles() {
    return this.data.get('maxFiles') || 1;
  }

  get maxFileSize() {
    return this.data.get('maxFileSize') || 256;
  }

  get acceptedFiles() {
    return this.data.get('acceptedFiles');
  }

  get addRemoveLinks() {
    return this.data.get('addRemoveLinks') || true;
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
    console.log(file)
  }

  start(callback) {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
        if (callback) callback();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener('progress', (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      '.dz-upload'
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit('processing', this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit('error', this.file, error);
    this.source.dropZone.emit('complete', this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit('success', this.file);
    this.source.dropZone.emit('complete', this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
  });
}
